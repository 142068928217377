<template>
  <div class="page-container">
    <pageBread
      :img="() => require('../../assets/image/contact_01.png')"
      text="联系我们"
      rightText="Contact us"
    ></pageBread>
    <div class="contact-pargram">
      <p>公司名称：{{ callList.company }}</p>
      <p>公司地址：{{ callList.companyAddress }}</p>
      <p>联系人：{{ callList.contacts }}</p>
      <p>咨询热线：{{ callList.tel }}</p>
      <p>微信公众号：{{ callList.wechatPublicPlatform }}</p>
      <p>邮箱：{{ callList.email }}</p>
      <p>新浪微博：{{ callList.weibo }}</p>
    </div>

    <pageBread
      :hideIcon="true"
      text="在线留言"
      style="padding-left: 15px"
    ></pageBread>
    <nqForm
      submitText="提交"
      @submit="submitForm"
      :values="values"
      :formList="[
        {
          label: '标题',
          key: 'title',
          type: 'input',
        },
        {
          label: '内容',
          key: 'content',
          type: 'textarea',
        },
      ]"
    ></nqForm>
  </div>
</template>
<script>
import { callInfo } from '../../utils/coupon.js'
export default {
  name: 'contact-us',
  data() {
    return {
      callList: {},
      values: {
        title: '',
        content: ''
      }
    }
  },
  created() {
    this._getList()
  },
  methods: {
    _getList() {
      this.$reqGet(callInfo.list).then((res) => {
        if (res.data.code === 200) {
          this.callList = res.data.data
        }
      })
    },
    submitForm(params) {
      // const params = {
      //   title: this.values.title,
      //   notes: this.values.content
      // }
      console.log(params)
      this.$reqPost(callInfo.callUs, params).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success('提交成功')
          window.location.reload()
        } else {
          this.$toast.success(res.data.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.contact-pargram {
  margin: 20px 20px;
  color: #999;
  font-size: 16px;
}
.contact-pargram p {
  padding: 3px 0px;
}
/deep/ .page-bread {
  margin: 10px;
}
</style>
