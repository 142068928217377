var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('pageBread',{attrs:{"img":function () { return require('../../assets/image/contact_01.png'); },"text":"联系我们","rightText":"Contact us"}}),_c('div',{staticClass:"contact-pargram"},[_c('p',[_vm._v("公司名称："+_vm._s(_vm.callList.company))]),_c('p',[_vm._v("公司地址："+_vm._s(_vm.callList.companyAddress))]),_c('p',[_vm._v("联系人："+_vm._s(_vm.callList.contacts))]),_c('p',[_vm._v("咨询热线："+_vm._s(_vm.callList.tel))]),_c('p',[_vm._v("微信公众号："+_vm._s(_vm.callList.wechatPublicPlatform))]),_c('p',[_vm._v("邮箱："+_vm._s(_vm.callList.email))]),_c('p',[_vm._v("新浪微博："+_vm._s(_vm.callList.weibo))])]),_c('pageBread',{staticStyle:{"padding-left":"15px"},attrs:{"hideIcon":true,"text":"在线留言"}}),_c('nqForm',{attrs:{"submitText":"提交","values":_vm.values,"formList":[
      {
        label: '标题',
        key: 'title',
        type: 'input',
      },
      {
        label: '内容',
        key: 'content',
        type: 'textarea',
      } ]},on:{"submit":_vm.submitForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }